import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Range from '../partials/Range'
import Check from '../partials/Check'
import ValidateFormByRef from '../../utils/ValidateFormByRef'

export class Login extends Component {
  handleContinue = (refs) => {
    const { getUrl } = this.props.store.login    
    const scroll = ValidateFormByRef(refs)

    if (scroll === true) {
      window.location = getUrl()
    } else {
      window.scrollTo({
        top: scroll,
        behavior: 'smooth',
      })
    }
  }

  handleClick = (name, value) => {
    const { setUrl } = this.props.store.login
    setUrl(value)
  }

  renderRange = () => {
    const { getOptions, getUrl } = this.props.store.login

    if (getOptions()) {
      return (
        <Range name='url'
          defaultValue={getUrl()}
          template='question'
          options={getOptions()}
          grid={{ xs: 12 }}
          onClick={this.handleClick}
          ref='url' />
      )
    }
    return
  }

  render () {
    return (
      <Grid container
        className='animate side'
        justify='center'
        alignItems='center'
        spacing={32}>

          <Grid item sm={6} xs={12}
            className='side-left'>
            <div className='logo' />
            <h1>{'Bienvenido al\nBanco Pichincha'}</h1>
            <p>¿Qué deseas hacer hoy?</p>

            {this.renderRange()}

            <Grid item sm={12} xs={12}>
              <Check label='Acepto las políticas de monitoreo y revisión de uso de esta red'
                name='terms'
                ref='terms' />
                <br />
                <br />
            </Grid>
            <Button className='containedPrimary'
              onClick={() => this.handleContinue(this.refs)} >
              Continuar
            </Button>
          </Grid>
          <Grid item sm={6} xs={12} 
            className='side-right'>
            <div className='bg-side' />
          </Grid>
      </Grid>
    )
  }
}

export default inject('store')(observer(Login))
