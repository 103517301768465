import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Check from '@material-ui/icons/FiberManualRecord'

export class Range extends Component {
  constructor (props) {
    super(props)
    const { defaultValue } = this.props

    this.state = {
      value: (defaultValue || null),
      isValidated: false,
      error: false
    }
  }

  componentDidMount() {
    const { defaultValue } = this.props

    if (defaultValue) {
      this.handleBlur()
    }      
  }
  
  isValidated = () => {
    const { isValidated } = this.state
    
    return isValidated
  }

  handleClick = (name, value) => {
    const { onClick } = this.props

    this.setState({
      value,
      isValidated: true,
      error: false
    })
    onClick(name, value)
  }

  handleBlur = () => {
    const { defaultValue } = this.props

    if(defaultValue) {
      this.setState({
        isValidated: true,
        error: false
      })
    } else {
      this.setState({
        isValidated: false,
        error: true
      })
    }
  }

  renderText = (template, option, check) => {
    switch (template) {
      case 'question': {
        return (
          <div className='template-question'>
            {option.label}
            {check}
          </div>
        )
      }
      default: {
        return (
          <div className='template-text'>
            {option.label}
            {check}
          </div>
        )
      }
    }
  }

  renderOptions = () => {
    const { name, template, options, grid } = this.props
    const { value, error } = this.state    
    
    return options.map((option, key) => {
      const active = String(option.value) === String(value)
      const className = error
        ? 'error' 
        : active ? 'active' : ''
      const check = active ? <Check className='animate' /> : null
      
      return(
        <Grid item md={grid.md} sm={grid.sm} xs={grid.xs} key={key}>
          <div id={option.id}
            onClick={() => this.handleClick(name, option.value)}
            className={`range-option ${className}`}>
              {this.renderText(template, option, check)}
          </div>
        </Grid>
      )
    })
  }

  render () {
    return (
      <React.Fragment>
        {this.renderOptions()}
      </React.Fragment>
    )
  }
}

export default Range
