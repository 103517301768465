import React, { Component } from 'react'
import { Provider } from 'mobx-react'
import JssProvider from 'react-jss/lib/JssProvider'
import generateClassName from '../utils/GenerateClassName'

import '../styles/app.scss'

import RootStore from '../stores'
import Wizard from './pages'

class App extends Component {
  render () {
    return (
      <JssProvider generateClassName={generateClassName}>
        <Provider store={RootStore}>
          <div className='container white-space'>
            <Wizard />
          </div>
        </Provider>
      </JssProvider>
    )
  }
}

export default App
