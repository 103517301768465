import { action, decorate } from 'mobx'

import LoginStore from './LoginStore'

class RootStore {
  constructor() {
    this.login = new LoginStore(this)
  }

  initialize = () => {
    this.login.initialize()
  }
}

decorate(RootStore, {
  initialize: action
})

const rootStore = new RootStore()

/* intercept(rootStore.person.person, 'spouse', (change) => {
  rootStore.setFieldValue(
    'firc', 'identification', 'display',
    change.newValue.status
  )
  return change
}) */

export default rootStore
