import { observable, action, decorate } from 'mobx'
import { isMobile, isIOS, isAndroid } from 'react-device-detect'

const redirect = {
  cuentaSimple: 'https://cuentasimple.pichincha.com/',
  bancaWeb: 'https://bancaweb.pichincha.com',
  bancaMovilIOs: 'https://apps.apple.com/ec/app/pichincha-banca-movil/id999191728',
  bancaMovilAndroid: 'https://play.google.com/store/apps/details?id=com.yellowpepper.pichincha&hl=en'
}

class LoginStore {
  constructor (RootStore) {
    this.rootStore = RootStore
    this._url = null
  }

  getOptions = () => {
    const options = [
      { 
        id: 'opcion-abrir-cuenta',
        label: 'Abrir una cuenta',
        value: redirect.cuentaSimple
      },
      { 
        id: 'opcion-banca-web',
        label: 'Ingresar a la banca web',
        value: redirect.bancaWeb
      }
    ]

    if (isMobile) {
      if (isIOS) {
        options.push({
          id: 'opcion-banca-movil-ios',
          label: 'Descargar la banca móvil',
          value: redirect.bancaMovilIOs           
        })
      } else if (isAndroid) {
        options.push({ 
          id: 'opcion-banca-movil-android',
          label: 'Descargar la banca móvil',
          value: redirect.bancaMovilAndroid          
        })
      }
    }

    return options
  }

  getUrl = () => {
    return this._url
  }

  setUrl = (url) => {
    this._url = url
  }
}

decorate(LoginStore, {
  fields: observable,
  setUrl: action
})

export default LoginStore
