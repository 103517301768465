import ReactDOM from 'react-dom'

const ValidateForm = (refs) => {
  let isValid = true

  function getTop (element) {
    const rect = ReactDOM.findDOMNode(element)
    return rect.offsetTop - 18
  }

  Object.keys(refs).forEach((ref) => {
    if (!refs[ref].isValidated()) {
      refs[ref].handleBlur()
      if (isValid === true) { isValid = getTop(refs[ref]) }
    }
  })

  return isValid
}

export default ValidateForm
