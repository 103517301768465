import React, { Component } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckMark from '@material-ui/icons/Check'

export class Check extends Component {
  constructor (props) {
    super(props)

    const { defaultValue } = this.props
    this.state = {
      checked: (defaultValue || false),
      isValidated: false,
      error: false
    }
  }

  componentDidMount() {
    const { defaultValue } = this.props

    if (defaultValue) 
      this.handleBlur()
  }
  
  isValidated = () => {
    const { isValidated } = this.state
    
    return isValidated
  }

  handleBlur = () => {
    const { checked } = this.state

    this.setState({
      isValidated: checked,
      error: !checked
    })
  }

  handleChange = e => {
    const { onChange } = this.props
    const { checked } = e.target

    this.setState({
      checked,
      isValidated: checked,
      error: false
    })
    if (onChange)
      onChange(e)
  }
  
  render () {
    const { name, label } = this.props
    const { checked, error } = this.state

    return (
      <FormControlLabel
          className={error ? 'error' : ''}
          control={
            <Checkbox name={name}              
              checked={checked}
              onChange={this.handleChange}
              icon={<CheckMark />}
              checkedIcon={<CheckMark />}
            />
          }
          label={label}
        />
    )
  }
}

export default Check
